import styles from './styles.module.css';
import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { SectionContainer } from 'src/components/sectionContainer';
import { DownloadIcon } from 'src/icons/download';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import { ContentfulPublicationResource, Maybe } from 'src/graphql-types';
import { ApolloError } from 'apollo-client';
import {
	SERVER_RESPONSE_NO_ACCESS,
	SERVER_RESPONSE_NO_ACCESS_TO_USER_TYPE,
	formatDate,
} from 'src/utils/common';
import * as Icons from 'src/icons';
import { LoginForMore } from 'src/components/loginComponents/loginForMore';
import { curatedTitle } from 'src/utils/curated-title';
import { defaultRichTextOptions, getRichText } from '../utils/richTextOptions/defaultOptions';

interface PageContext {
	pageContext: {
		publication: Maybe<ContentfulPublicationResource>;
		loading?: boolean;
		error?: ApolloError;
		refetch?: (params?: any) => any;
	};
}

interface MessageProps {
	message: string;
}

const SimpleMessageContent = (props: MessageProps) => {
	return (
		<SectionContainer withBorderTop>
			<p className={styles.message}>{props.message}</p>
		</SectionContainer>
	);
};

export default class PublicationDetailsTemplate extends React.Component<PageContext> {
	render() {
		const { publication, loading = false, error, refetch } = this.props.pageContext;
		const notLoggedIn = error && error.message === SERVER_RESPONSE_NO_ACCESS;
		const notAuthorised = error && error.message === SERVER_RESPONSE_NO_ACCESS_TO_USER_TYPE;

		const getContent = () => {
			if (loading) {
				return <SimpleMessageContent message="Loading content, please wait" />;
			}
			if (error) {
				if (notLoggedIn) {
					return <LoginForMore onRefetch={refetch} withSectionContainer />;
				} else if (notAuthorised) {
					return <SimpleMessageContent message="this content is not available" />;
				} else {
					return (
						<SimpleMessageContent message="There was a problem getting the resource, please try again later." />
					);
				}
			}
			if (publication) {
				const {
					contentful_id,
					summary,
					publicationDate,
					pages,
					otherLanguageAssets,
					publicationFile,
					externalPublicationUrl,
					title,
					species,
					thumbnailLink,
				} = publication;

				const Icon = (Icons[thumbnailLink?.thumbnailIcon as keyof typeof Icons] ||
					undefined) as FunctionComponent<any>;
				const imageSrc = thumbnailLink?.thumbnailImage?.file?.url;
				return (
					<SectionContainer
						isGrey
						withBorderTop
						style={{ display: 'flex', justifyContent: 'center' }}
					>
						<div>
							<h6 className={styles.publicationType}>Publication</h6>
							<h4 className={styles.publicationTitle}>{title}</h4>
							{publicationDate && (
								<p className={styles.publicationDate}>{formatDate(publicationDate)}</p>
							)}
							<div className={styles.publicationSection}>
								<div className={styles.detailsContainer}>
									{getRichText(summary, defaultRichTextOptions)}
									<div className={styles.imageContainer}>
										{imageSrc ? <img src={imageSrc} /> : Icon && <Icon />}
									</div>
									{publicationFile?.file?.url && (
										<a
											className={styles.fileLink}
											href={publicationFile?.file?.url || '/'}
											download
											target="_blank"
										>
											<DownloadIcon style={{ paddingLeft: '0' }} />
											Download Publication (English)
										</a>
									)}
									{externalPublicationUrl && (
										<a className={styles.fileLink} href={externalPublicationUrl} target="_blank">
											Go to Publication
										</a>
									)}
								</div>
								<div className={styles.metadataContainer}>
									{pages && (
										<div>
											<h6 className={styles.metadataTitle}>Pages</h6>
											<p className={styles.metadataParagraph}>{Number(pages).toLocaleString()}</p>
										</div>
									)}
									{(species?.length || 0) > 0 && (
										<div>
											<h6 className={styles.metadataTitle}>Species</h6>
											<p className={styles.metadataParagraph}>
												{species!
													.filter((s) => s)
													.map((s, i) => {
														const separator = i > 0 ? ',' : '';
														return <span key={s || i}>{`${separator} ${s}`}</span>;
													})}
											</p>
										</div>
									)}
									{otherLanguageAssets?.length && (
										<div>
											<h6 className={styles.metadataTitle}>Other Languages</h6>
											<p className={styles.metadataParagraph}>
												{otherLanguageAssets
													.filter((ol: any) => ol)
													.map((ol: any) => {
														const href = ol.theAsset?.file?.url || ol.externalAssetUrl;
														const download = ol.theAsset?.file?.url !== undefined;
														const target = download ? undefined : '_blank';
														if (href) {
															return (
																<p>
																	<a
																		key={ol.contentful_id}
																		className={styles.metadataHyperlink}
																		href={href}
																		download={download}
																		target={target}
																	>
																		{ol.language}
																	</a>
																</p>
															);
														}
														return undefined;
													})}
											</p>
										</div>
									)}
								</div>
							</div>
						</div>
					</SectionContainer>
				);
			}

			return (
				<SimpleMessageContent message="There was a problem getting the resource, please try again later." />
			);
		};

		return (
			<DefaultLayoutTemplate showLogin={notLoggedIn} onLoginSuccess={refetch}>
				<Helmet>
					<title>{curatedTitle(this.props.pageContext.publication?.title)}</title>
				</Helmet>
				{getContent()}
			</DefaultLayoutTemplate>
		);
	}
}
