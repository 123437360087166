import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import ProtectedPublicationGraphql from './protected-publication.graphql';
import PublicationDetailsTemplate from './publicationDetailsTemplate';
import { NetworkStatus } from 'apollo-client';
import { parse } from 'flatted';

interface PageContext {
	pageContext: {
		id: string;
	};
}

interface QueryResult {
	contentfulPublication: any;
}

const ProtectedPublicationDetails = (props: PageContext) => {
	const { id } = props.pageContext;
	const { data, loading, error, refetch, networkStatus } = useQuery<QueryResult>(
		ProtectedPublicationGraphql,
		{
			variables: {
				id,
			},
			notifyOnNetworkStatusChange: true,
		}
	);

	let publication = undefined;

	if (data) {
		publication = JSON.parse(JSON.stringify(data.contentfulPublication));
		if (publication.summary?.json) {
			publication.summary = {
				json: parse(publication.summary.json), // parses circular references
			};
		}
	}

	return (
		<PublicationDetailsTemplate
			pageContext={{
				publication,
				loading: loading || networkStatus === NetworkStatus.refetch,
				error,
				refetch,
			}}
		/>
	);
};

export default class ProtectedPublicationDetailsComponent extends React.Component<PageContext> {
	render() {
		return <ProtectedPublicationDetails {...this.props} />;
	}
}
